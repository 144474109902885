var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("q-table", {
            attrs: {
              title: "",
              data: _vm.dataTable,
              columns: _vm.columns,
              "row-key": "_OID",
              pagination: _vm.pagination,
              "rows-per-page-options": [5, 10, 15, 20, 50, 100, 0],
              dense: "",
              separator: "cell",
              "no-data-label": "brak danych",
              "no-results-label": "brak wyników",
              "loading-label": "ładuję dane...",
              "rows-per-page-label": "rekordów na stronę",
              "selected-rows-label": function(rowsNumber) {
                return "zaznaczono " + rowsNumber + " pozycji"
              },
              "pagination-label": function(start, end, total) {
                return start + "-" + end + " z " + total
              }
            },
            on: {
              "update:pagination": function($event) {
                _vm.pagination = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "top-left",
                  fn: function(props) {
                    return [
                      _c("q-btn", {
                        attrs: { color: "primary", label: "Odśwież dane" },
                        on: { click: _vm.fetchData }
                      })
                    ]
                  }
                },
                {
                  key: "body-cell-free",
                  fn: function(props) {
                    return _vm.sid === "12" && _vm.dataTable
                      ? _c("q-td", { attrs: { props: props } }, [
                          _vm._v(
                            "\n           " +
                              _vm._s(_vm.humanStorageSize(props.value)) +
                              "\n         "
                          )
                        ])
                      : _vm._e()
                  }
                },
                {
                  key: "body-cell-total",
                  fn: function(props) {
                    return _vm.sid === "12" && _vm.dataTable
                      ? _c("q-td", { attrs: { props: props } }, [
                          _vm._v(
                            "\n           " +
                              _vm._s(_vm.humanStorageSize(props.value)) +
                              "\n         "
                          )
                        ])
                      : _vm._e()
                  }
                }
              ],
              true
            )
          })
        ],
        1
      )
    ]),
    _vm.sid === "12" && _vm.dataTable && _vm.charts
      ? _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.charts, function(item) {
            return _c(
              "div",
              { key: item.id, staticClass: "col-4" },
              [_c("chart", { attrs: { "auto-resize": "", options: item } })],
              1
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }