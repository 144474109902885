<template>
   <div>
      <!--
      <h3 v-html="title"></h3>
      <div class="loading" v-if="loading">Ładowanie danych...</div>
      <div v-if="error" class="error">{{error}}</div>
      <q-data-table id="el_getChapterGenTimeCountByUserLast7d" :data="items" :config="config" :columns="columns" v-if="id=='1' && items"></q-data-table>
      <q-data-table id="el_getChapterGenTimeCountLast7d" :data="items" :config="config" :columns="columns" v-if="id=='2' && items"></q-data-table>
      <q-data-table id="el_getChapterGenTimeCountWithFilesLast7d" :data="items" :config="config" :columns="columns" v-if="id=='3' && items"></q-data-table>
      <q-data-table id="el_getChapterStates" :data="items" :config="config" :columns="columns" v-if="id=='4' && items"></q-data-table>
      <q-data-table id="el_getCMSGenTimeCountLast7d" :data="items" :config="config" :columns="columns" v-if="id=='5' && items"></q-data-table>
      <q-data-table id="el_getFilesGenTimeCountLast7d" :data="items" :config="config" :columns="columns" v-if="id=='6' && items"></q-data-table>
      <q-data-table id="el_getFilesCopiedGenTimeCountLast7d" :data="items" :config="config" :columns="columns" v-if="id=='7' && items"></q-data-table>
      <q-data-table id="el_getFilesPathGenTimeCountLast7d" :data="items" :config="config" :columns="columns" v-if="id=='8' && items"></q-data-table>
      <q-data-table id="el_getExecutorMethodsListAll" :data="items" :config="config" :columns="columns" v-if="id=='9' && items"></q-data-table>
      <q-data-table id="el_getExecutorMethodsListAllWorking" :data="items" :config="config" :columns="columns" v-if="id=='9Working' && items"></q-data-table>
      <q-data-table id="el_getExecutorMethodsListAllReady" :data="items" :config="config" :columns="columns" v-if="id=='9Ready' && items"></q-data-table>
      <q-data-table id="el_getExecutorMethodsListAllProcessing" :data="items" :config="config" :columns="columns" v-if="id=='9Processing' && items"></q-data-table>
      <q-data-table id="el_getExecutorMethodsListAllDeleted" :data="items" :config="config" :columns="columns" v-if="id=='9Deleted' && items"></q-data-table>
      <q-data-table id="el_getSessionsGenActiveLast1d" :data="items" :config="config" :columns="columns" v-if="id=='10' && items"></q-data-table>
      <q-data-table id="el_getDBPGStatActivity" :data="items" :config="config" :columns="columns" v-if="id=='11' && items"></q-data-table>
      <q-data-table id="el_getServerBasicInfo" :data="items" :config="config" :columns="columns" v-if="id=='12' && items"></q-data-table>
      <q-data-table id="el_getDBPGDatabaseSize" :data="items" :config="config" :columns="columns" v-if="id=='14' && items"></q-data-table>
      <q-data-table id="el_getDBPGStatIOUserTables" :data="items" :config="config" :columns="columns" v-if="id=='15' && items"></q-data-table>
      <q-data-table id="el_getDBPGStatUserTables" :data="items" :config="config" :columns="columns" v-if="id=='16' && items"></q-data-table>
      <q-data-table id="el_getExecutorMethodsGroupByStatus" :data="items" :config="config" :columns="columns" v-if="id=='17' && items"></q-data-table>
      -->
      <div class="row">
        <div class="col-12">
          <q-table
            title=""
            :data="dataTable"
            :columns="columns"
            row-key="_OID"
            :pagination.sync="pagination"
            :rows-per-page-options="[5,10,15,20,50,100,0]"
            dense
            separator="cell"
            no-data-label="brak danych"
            no-results-label="brak wyników"
            loading-label="ładuję dane..."
            rows-per-page-label="rekordów na stronę"
            :selected-rows-label="(rowsNumber)=>`zaznaczono ${rowsNumber} pozycji`"
            :pagination-label="(start,end,total)=>`${start}-${end} z ${total}`"
          >
            <!--
            <q-tr slot="top-row" slot-scope="props">
              <q-td colspan="100%">
                <strong>Extra top row</strong>
              </q-td>
            </q-tr>
            <q-tr slot="bottom-row" slot-scope="props">
              <q-td colspan="100%">
                <strong>Extra bottom row</strong>
              </q-td>
            </q-tr>
            -->
            <template slot="top-left" slot-scope="props">
              <q-btn color="primary" @click="fetchData" label="Odśwież dane" />
            </template>
            <q-td slot="body-cell-free" slot-scope="props" :props="props" v-if="(sid==='12') && dataTable">
              {{humanStorageSize(props.value)}}
            </q-td>
            <q-td slot="body-cell-total" slot-scope="props" :props="props" v-if="(sid==='12') && dataTable">
              {{humanStorageSize(props.value)}}
            </q-td>
          </q-table>
        </div>
      </div>
      <div class="row" v-if="(sid==='12') && dataTable && charts">
        <div class="col-4" v-for="item in charts" :key="item.id">
          <chart auto-resize :options="item"></chart>
        </div>
      </div>
   </div>
</template>

<script type="text/javascript">
import { format } from 'quasar'

export default {
  name: 'Statystyki',
  props: ['id', 'tabitem'],
  data: function () {
    let ret = {
      loading: false,
      error: null,
      title: '',
      items: null,
      sid: '',

      dataLoaded: false,
      dataTable: [],
      pagination: {
        sortBy: '', // String, column "name" property value
        descending: false,
        page: 1,
        rowsPerPage: 15 // current rows per page being displayed
      },
      columns: [],
      charts: []
    }
    return ret
  },
  mounted () {
    this.fetchData()
  },
  filters: {
    date2str (value) {
      if (!value) {
        return ''
      }
      return value.slice(0, 19).replace('T', ' ')
    }
  },
  methods: {
    humanStorageSize (v) {
      return format.humanStorageSize(v)
    },
    async fetchData () {
      // debugger
      let id, meta
      if (this.tabitem.tabid) {
        id = this.tabitem.tabid
        meta = this.tabitem.tabdata
      } else {
        id = this.id
        meta = this.$route.meta[id]
        if (this.$route.meta['stats_route']) {
          return
        }
      }
      this.sid = id
      this.error = this.post = null
      this.title = meta['title']
      this.columns = meta['columns'] || []
      this.charts = []
      this.dataTable = []

      let [response] = await this.$icor.main.statsGet(meta['DATA_URL'])
      // debugger
      if (response.status === 200) {
        if ((response.data === '') || (response.data === []) || (response.data === {})) {
          this.error = 'brak danych'
          this.$q.notify({type: 'warning', position: 'bottom-right', message: 'Brak danych.'})
          return
        }
        if (response.data.hasOwnProperty('status') && response.data.hasOwnProperty('info') && (response.data['status'] !== 0)) {
          this.error = response.data['info']
          this.$q.notify({type: 'negative', position: 'bottom-right', message: response.data['info']})
        } else {
          this.dataTable = response.data || []
          if (id === '12') {
            this.addCharts12()
          }
        }
      } else {
        this.$q.notify({type: 'negative', position: 'bottom-right', message: 'Wystąpił błąd w komunikacji. Skontaktuj się z administratorem.'})
      }
    },
    addCharts12 () {
      var chartOptions, dt, i, item
      for (i = 0; i < this.dataTable.length; i++) {
        item = this.dataTable[i]
        dt = {
          value: item.percent,
          name: 'dysk ' + item.disk + '\n' + format.humanStorageSize(item.total)
        }
        chartOptions = {
          id: i,
          backgroundColor: '#ffffff',
          color: ['#37A2DA', '#32C5E9', '#67E0E3'],
          series: [{
            name: 'Wolne miejsce na dysku',
            type: 'gauge',
            startAngle: 180,
            center: ['50%', '80%'],
            radius: 120,
            splitNumber: 12,
            endAngle: 0,
            min: 0,
            max: 100,
            axisLine: {
              show: true,
              lineStyle: {
                width: 20,
                shadowBlur: 0,
                color: [
                  [1 / 12, '#FD0000'],
                  [2 / 12, '#F82804'],
                  [3 / 12, '#FD5901'],
                  [4 / 12, '#FA9900'],
                  [5 / 12, '#FA9900'],
                  [6 / 12, '#FABB00'],
                  [7 / 12, '#FABB00'],
                  [8 / 12, '#FBC402'],
                  [9 / 12, '#B5FB02'],
                  [10 / 12, '#B5FB02'],
                  [11 / 12, '#05FA1D'],
                  [12 / 12, '#05FA1D']
                ]
              }
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: true,
              fontSize: 25,
              formatter: function (param) {
                if ((param % 5) === 0) {
                  return param
                }
              }
            },
            splitLine: {
              show: true,
              length: 20,
              lineStyle: {
                color: 'white',
                width: 2
              }
            },
            pointer: {
              show: true,
              length: '70%'
            },
            itemStyle: {
              normal: {
                color: '#FFFFFF',
                borderColor: '#92DAFF',
                borderWidth: '2'
              }
            },
            detail: {
              show: false,
              offsetCenter: [0, 10],
              textStyle: {
                fontSize: 30,
                fontFamily: 'Arial',
                fontWeight: 'bold',
                color: '#6495ED'
              }
            },
            data: [
              dt
            ]
          }]
        }
        this.charts.push(chartOptions)
      }
    }
  }
}
</script>

<style scoped>
.echarts {
  height: 200px;
  width: 33vw;
}
/*
.q-data-table table td, .q-data-table table th {
  white-space: pre-wrap !important;
}
*/
</style>
